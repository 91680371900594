@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;500;700&family=Space+Grotesk:wght@400;500;700&display=swap');

$background: #16101F;
$white: #fff;
$text: white;
$border: grey;
$hightlight-text: #9f7de8;
$button-background: #2069f7;
$font: 'Plus Jakarta Sans', sans-serif;
$secondary-font: 'Space Grotesk', sans-serif;
$contact-name-input-box-border: #8858ED;

body {
  background-color: $background;
  color: $text;
  font-family: $font;
  box-sizing: border-box;
  margin: 0;
}

button {
  cursor: pointer;
}

body::-webkit-scrollbar {
  display: none;
}

.root {
  margin: 0 !important;
}

@media screen and (max-width: 480px) {
  body {
    margin: 0;
  }
}

/*
** Navbar Styles
*/

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-logo {
  width: 5rem;
  height: 5rem;
  margin: 1rem 0 1rem 3rem;
}

.nav-link {
  margin: 0 10px;
  font-size: 15px;
  color: $text;
  text-decoration: none;
}

.nav-link:hover {
  color: $hightlight-text;
}

@media screen and (max-width: 480px) {

  .nav-logo {
    width: 4rem;
    height: 4rem;
    margin: 0.5rem 0 0.5rem 1rem;
  }

  .nav-links {
    margin-right: 0.5rem;
  }

}

/*
** Home Page Styles
*/

/*
** Hero Styles
*/

.hero {
  width: 81%;
  margin: 0 auto;
  margin-top: 3rem;
  background-image: url("./assets/hero-background.webp");
  background-repeat: no-repeat;
  padding: 2%;
  display: flex;
  flex-direction: row;
  background-size: contain;
}

.hero-left {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.hero-text {
  font-size: 4rem;
  font-weight: bold;
}

.blur-text-container {
  margin: 1.5rem 0;
  backdrop-filter: blur(10px);
  padding: 7px 10px;
  border: 1px solid #918691;
  border-radius: 12px;
  width: fit-content;
}

.blur-text {
  font-size: 1.2rem;
}

.store-link-img {
  margin: 20px 5px;
  height: 40px;
}

.hero-right {
  width: 50%;
}

.hero-image {
  // margin-top: -10rem;
  margin-top: -6.5rem;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 480px) {

  .hero {
    width: 90%;
    margin: 0 auto;
    margin-top: 3rem;
    background-image: url("./assets/hero-background.webp");
    background-repeat: unset;
    padding: 5%;
    display: flex;
    flex-direction: column !important;
    border-radius: 12%;
  }

  .hero-left {
    width: 100%;
  }

  .hero-text {
    font-size: 2rem;
    font-weight: bold;
  }

  .blur-text-container {
    margin: 1.5rem 0;
    backdrop-filter: blur(10px);
    padding: 7px 10px;
    border-radius: 12px;
  }
  
  .store-links {
    display: flex;
    flex-direction: row;
  }

  .blur-text {
    font-size: .7rem;
  }

  .store-link-img {
    margin: 7px 5px;
    height: 35px !important;
  }

  .hero-right {
    display: none !important;
  }

}

// .form-main-container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.home-graphics {
  width: 20rem;
}

.form-header {
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
}

.form-header-white {
  color: $text;
}

.form-header-red {
  color: #FF4040;
}

.form-text {
  font-weight: 700;
  font-size: 0.8rem;
  text-align: center;
  margin: 1rem 0;
}

.form {
  padding: 0 1rem;
}

.form-input-group {
  display: flex;
  flex-direction: column;
  margin: 0.4rem 0;
  width: 20rem;
}

.form-input-header {
  font-size: 0.8rem;
  font-weight: 700;
  margin-left: 3.5rem;
}

.form-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.form-input-box {
  height: 1.5rem;
  margin-left: 0.7rem;
  outline: none;
  width: 100%;
  font-family: $font;
  padding-left: 0.5rem;
}

.form-select {
  height: 2rem;
  margin-left: 0.8rem;
  outline: none;
  width: 100%;
  padding-left: 0.3rem;
  font-family: $font;
}

.text-container {
  text-align: center;
  margin: 3rem 12rem;
}

.join-button {
  background-color: $button-background;
  color: $text;
  padding: 2px 2rem;
  font-family: $font;
  font-weight: 700;
  border: 2px solid $text;
}

.home-text {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.7rem;
  margin: 0 1rem;
}

.home-text-mobile {
  display: none;
}

.text-yellow {
  color: #F4F033;
}

.border {
  border-color: tomato;
}

@media screen and (max-width: 480px) {

  .graphics-container {
    display: none;
  }

  .form-text {
    width: 280px;
  }

  .text-container {
    display: none;
  }

  .home-text-mobile {
    display: block;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.7rem;
    width: 300px;
    text-align: center;
    margin: 1rem auto;
  }

}

/*
** Contact Page Styles
*/

.contact-header {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
}

.contact-form {
  margin-bottom: 5rem;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.contact-form-name-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
}

.contact-form-name {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.contact-form-name-text {
  padding-left: 1.5rem;
  padding-bottom: 0.5rem;
}

.contact-name-input-box {
  outline: none;
  width: 15rem;
  height: 3rem;
  border: 2px solid $contact-name-input-box-border;
  background-color: transparent;
  padding-left: 1rem;
}

.contact-name-input-box:hover {
  border: 2px solid $white;
  background-color: $contact-name-input-box-border;
}

.top-left-border {
  border-top-left-radius: 1.7rem;
}

.top-right-border {
  border-top-right-radius: 1.7rem;
}

.message-input-box {
  outline: none;
  width: 30.6rem;
  height: 9rem;
  border: 2px solid $contact-name-input-box-border;
  background-color: transparent;
  resize: none;
  padding-left: 1rem;
}

.message-input-box:hover {
  border: 2px solid $white;
  background-color: $contact-name-input-box-border;
}

.contact-form-button-container {
  display: flex;
  justify-content: center;
  // flex-direction: row;
  width: 100%;
}

.contact-form-button {
  background-color: $contact-name-input-box-border;
  color: $white;
  width: 32.1rem;
  height: 3rem;
  font-family: $font;
  font-size: large;
  margin-top: 0;
}

.contact-form-button:hover {
  background-color: $contact-name-input-box-border;
  border-color: $white;
}

@media screen and (max-width: 480px) {

  // .contact-form {
  //   width: 280px !important;
  // }

  .contact-form-name-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0rem;
    border-radius: 0;
  }

  .contact-form-name-text {
    margin: 0 auto;
    margin-top: 1rem;
  }

  .contact-name-input-box {
    width: 300px;
    border-radius: 0;
  }

  .message-input-box {
    width: 300px;
    margin: 0 auto;
  }

  .contact-form-button {
    width: 320px;
    margin-top: 1rem;
  }

}

/*
** Footer Styles
*/

.tc-policy {
  display: flex;
  flex-direction: row;
}

.policy {
  flex-direction: row;
}

.policy-link-container {
  margin: 7px 1rem !important;
  text-align: center;
}

.policy-link {
  font-size: 15px;
  color: $text;
  text-decoration: none;
}

.footer-mobile {
  display: none;
}

@media screen and (max-width: 480px) {

  .footer {
    display: none !important;
  }

  .footer-mobile {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-end;
    margin: 0 auto !important;
    width: 100% !important;
    margin-top: 30% !important;
    padding-bottom: 0rem !important;
  }

  .footer-text {
    margin: 0 auto !important;
    text-align: center;
  }

  .policy-text {
    margin: 0 auto !important;
    text-align: center;
    width: 8rem !important;
    z-index: 999 !important;
  }

  .policy-link {
    font-size: 10px;
  }

  .footer-bottom-mobile {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
  }

  .border-bottom {
    border-bottom: 1px solid #B0B0B0;
    padding-bottom: 1rem !important;
  }

  .logo {
    display: none;
  }

  .tc-policy {
    display: flex;
    width: 75% !important;
    justify-content: flex-end !important;
  }

  .policy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 0.3rem;
  }

  .community {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 0.3rem;
  }

  .social-links {
    justify-content: center;
    margin: 1.3rem 0;
  }

  .social-link {
    margin: 0 0.7rem;
  }
}

// Home screen styles

@media screen and (max-width: 480px) {

  .home-screen {
    // background-image: url("./assets/home-background-mobile.svg") !important;
    max-width: 100vw !important;
  }

  .lsoon-img {
    display: none !important;
  }

  .app-launch {
    margin-bottom: -10%;
  }

  .lsoon-img-mobile {
    display: block !important;
    width: auto;
    max-width: 272px;
  }

  .home-title {
    display: none;
  }

  .home-title-mobile {
    display: flex !important;
    flex-direction: row !important;
    width: 90% !important;
    margin: 0 30px !important;
    margin-top: 40px !important;
    margin-bottom: 0px !important;
  }

  .nav {
    margin: 0 10px !important;
    padding-top: 15px !important;
  }

  .home-logo-link {
    width: 25% !important;
  }

  .being-abroad-logo {
    width: 100% !important;
  }

  .navigation-pages {
    font-weight: 500;
    font-size: 10px !important;
  }

  .navigation-page {
    margin: 0 10px;
  }

  .home-title-text {
    font-size: 25px !important;
    line-height: 32px !important;
  }

  .home-vector {
    display: none !important;
  }

  .home-vector-mobile {
    display: block !important;
    position: absolute;
    overflow: hidden !important;
    width: 45% !important;
    // top: 8% !important;
    left: 53% !important;
  }

  .home-subtitle {
    width: 90% !important;
    padding: 0px 10px !important;
    margin-top: 7px !important;
  }

  .home-subtitle-text {
    font-weight: 600 !important;
    font-size: 15px !important;
  }

  .handpick {
    display: none !important;
  }

  .handpick-mobile {
    display: block !important;
    margin: 0 auto;
    margin-top: 10%;
    width: 90%;
    padding: 30px 0;
    border-radius: 22px;
    border: 0.5px solid rgba(138, 138, 138, 0.37);
    background: #18181A;
    box-shadow: 2px 18px 19px -14px rgba(8, 8, 9, 0.38);
  }

  .handpick-text {
    font-size: 20px !important;
    margin: 20px 5% !important;
  }

  .discount-btn {
    font-weight: 700 !important;
    font-size: 14px !important;
    width: 45% !important;
    margin: 0px 10px !important;
    margin-top: 20px !important;
    padding: 10px 0 !important;
  }

  .footer-end {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    margin: 0px 10px !important;
  }

  .footer-text {
    position: sticky;
    z-index: 999 !important;
  }

  .social-logos {
    display: flex;
    flex-direction: row !important;
    margin: 10px 0px !important;
    z-index: 999 !important;
  }

  .social-logo {
    height: 10px !important;
    margin: 0 7px;
  }

  .select-button-container {
    border-radius: 22px 80px 22px 22px !important;
    width: 80% !important;
    font-size: 22px !important;
  }

  .benefit-container {
    width: 100%;
  }

  .benefit {
    margin: 10px 7px !important;
  }

  .benefit-title {
    display: none;
  }

  .benefit-title-mobile {
    display: flex !important;
    align-items: center;
    font-size: 22px !important;
    font-weight: 700;
    margin-top: 10px;
  }

  .spiral {
    flex-direction: column !important;
    justify-content: center !important;
    text-align: center;
  }

  .spiral-img {
    width: auto;
    max-width: 82px !important;
    margin-right: 5%;
    margin-bottom: 10px;
  }

  .spiral-text {
    width: 70% !important;
    font-size: 17px !important;
  }

  .spiral-bold-text {
    font-size: 25px !important;
  }

  .benefit-light-text {
    font-size: 12px !important;
  }

  .benefit-bold-text {
    font-size: 15px !important;
  }

  .benefit-img {
    max-width: 28px !important;
    margin-left: 5px !important;
  }

  .benefit-small-tile {
    width: 34% !important;
  }

  .benefit-large-tile {
    width: 100% !important;
  }

  .event-container {
    display: flex;
    border-radius: 30px !important;
    flex-direction: column !important;
    width: 95% !important;
    margin: 3% auto;
    padding: 2% 0%;
    margin-top: 10% !important;
  }

  .event {
    flex-direction: column !important;
  }

  .event-details {
    width: 100% !important;
    margin-left: 0 !important;
    align-items: center !important;
  }

  .event-logo {
    margin-top: 10% !important;
    width: 75% !important;
  }

  .event-text {
    font-size: 24px !important;
    text-align: center;
    line-height: 1.5em !important;
    margin-top: 5%;
  }

  .event-register-button {
    display: flex !important;
    width: 60% !important;
    margin-bottom: 5% !important;
    justify-content: center !important;
  }

  .luma-frame {
    margin: 3% 0;
    border-radius: 20px !important;
    width: 87% !important;
    height: 720px !important;
  }
  
  .event-benefits-container {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5% !important;
    padding: 3% 1%;
  }

  .event-star {
    width: 15% !important;
  }

  .benefits-title {
    display: flex;
    align-items: center;
    width: 90% !important;
    border-bottom: 1px #FFD93B dashed;
    padding-bottom: 5% !important;
  }

  .benefits-title-text {
    font-size: 22px !important;
    margin-left: 5% !important;
  }

  .event-benefits {
    grid-template-columns: repeat(2, 6fr) !important;
    width: 90% !important;
    margin-top: 10% !important;
  }

  .event-benefit {
    flex-direction: column !important;
    width: 100% !important;
    padding: 10px 0 !important;
  }

  .event-benefit-text {
    text-wrap: wrap;
    text-align: center;
  }

  .benefit-img {
    min-width: 40% !important;
    margin-right: 5% !important;
    margin-bottom: 7%;
  }

}

.benefit-title-mobile {
  display: none;
}

.event-container {
  display: flex;
  flex-direction: row;
  border-radius: 50px;
  border: 0.5px solid #8A8A8A;
  background: #18181A;
  box-shadow: 2px 18px 19px -14px rgba(8, 8, 9, 0.38);
  width: 90%;
  margin: 3% auto;
  padding: 2% 0%;
}

.event {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.event-logo {
  width: 20%;
}

.event-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  margin-left: 10%;
}

.event-register-button {
  margin-top: 5%;
}

.event-text {
  font-family: Plus Jakarta Sans;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.luma-frame {
  border: none;
  border-radius: 25px;
  margin-left: 20px;
  width: 80%;
  height: 400px;
  padding-right: 0 !important;
}

.page-container {
  scrollbar-width: none !important;
}

.page-container::-webkit-scrollbar {
  display: none !important;
}

.luma-checkout--button {
  display: flex !important;
  background-color: #F20944 !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 35px !important;
  font-size: 17px !important;
}

.event-benefits-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  border-radius: 25px;
  border: 0.5px solid #4B4B4B;
  background: #0D0D0D;
  box-shadow: 2px 18px 19px -14px rgba(8, 8, 9, 0.38);
  padding: 3% 3%;
}

.benefits-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.event-star {
  width: 7%;
  margin: 0 3%;
}

.benefits-title-text {
  font-family: Plus Jakarta Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.event-benefits {
  display: grid;
  grid-template-columns: repeat(3, 4fr);
  column-gap: 1%;
}

.event-benefit {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 1.5% 2%;
  padding: 20px 15px;
}

.benefit-img {
  margin-right: 5%;
}

.home-screen {
  // background-image: url("./assets/home-background-desktop.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.home-vector {
  position: absolute;
  width: 25%;
  left: 60%;
}

.home-vector-mobile {
  display: none;
}

.select-button-container {
  display: flex;
  flex-direction: row;
  width: 27%;
  margin-left: 4.5%;
  margin-top: 2rem;
  padding: 10px 20px;
  border-radius: 40px 112px 40px 40px;
  background: linear-gradient(46deg, #270685 0%, #9B45F3 100%);
  box-shadow: 2px 18px 16px -14px rgba(8, 9, 11, 0.66);
  font-size: 25px;
  font-weight: 700;
}

.button-container {
  display: flex;
  align-items: flex-start;
  width: 60% !important;
  flex-direction: column !important;
}

.cash-vector-container {
  width: 30% !important;
}

.cash-vector {
  width: 100%;
  margin-left: 40%;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 70px;
  padding-top: 30px;
}

.home-logo-link {
  width: 12%;
  text-decoration: none;
}

.being-abroad-logo {
  width: 100%;
}

.navigation-pages {
  font-weight: 500;
  font-size: 20px;
}

.navigation-page {
  margin: 0 10px;
}

.navigation-link {
  text-decoration: none;
  color: #fff;
}

.home-title {
  width: 70%;
  margin: 0 70px;
  margin-top: 70px;
}

.home-title-text {
  font-style: normal;
  font-size: 60px;
  color: #FFFFFF;
  line-height: 3.8rem;
}

.home-subtitle {
  width: 40.7%;
  padding: 0px 70px;
  margin-top: 7px;
}

.home-subtitle-text {
  font-weight: 600;
  font-size: 22px;
  line-height: 23px;
  color: #FFFFFF;
}

.discount-btn {
  font-weight: 700;
  font-size: 17px;
  padding: 10px 10px;
  align-self: flex-start;
  width: 70%;
  border: 1px solid #FFF;
  border-radius: 16px;
  background: #FFAD39;
  box-shadow: 4px 3px 8px rgba(9, 8, 14, 0.28);
  color: #0D0D0D;
  font-family: $secondary-font;
  font-weight: 700;
  margin-top: 20px;
  text-transform: uppercase;
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.handpick-mobile {
  display: none;
}

.handpick {
  margin: 0 auto;
  margin-top: 10%;
  width: 90%;
  padding: 30px 0;
  border-radius: 22px;
  border: 0.5px solid rgba(138, 138, 138, 0.37);
  background: #18181A;
  box-shadow: 2px 18px 19px -14px rgba(8, 8, 9, 0.38);
  z-index: 100;
}

.handpick-text {
  font-weight: 500;
  font-size: 27px;
  margin: 20px 10%;
}

.benefits {
  margin: 0 auto;
  margin-top: 5%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: linear-gradient(46deg, #270685 0%, #9B45F3 100%);
  box-shadow: 2px 18px 16px -14px rgba(8, 9, 11, 0.66);
}

.benefit-container {
  display: flex;
  align-self: center;
  flex-wrap: wrap;
  width: 90%;
}

.benefit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #0D0E1A;
  border-radius: 26px;
  border: 1px solid rgba(109, 89, 129, 0.68);
  background: #0D0E1A;
  box-shadow: -4px 7px 16px -2px rgba(10, 11, 13, 0.55);
  margin: 10px 15px;
  padding: 10px 15px;
}

.benefit-small-tile {
  width: 25%;
}

.benefit-large-tile {
  width: 35%;
}

.benefit-title {
  font-size: 32px;
  font-weight: 700;
}

.benefit-text-container {
  width: 70%;
  font-family: $secondary-font;
}

.benefit-light-text {
  font-size: 24px;
}

.benefit-bold-text {
  font-weight: 700;
  font-size: 28px;
}

.benefit-img {
  width: auto;
  height: auto;
  max-width: 50px;
  max-height: 50px;
}

.spiral {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.spiral-img {
  width: auto;
  max-width: 142px;
  margin-right: 5%;
}

.spiral-text {
  font-size: 22px;
  font-weight: 300;
}

.spiral-bold-text {
  color: "#FFF";
  font-size: 45px;
  font-family: $secondary-font;
  font-weight: 700;
}

.footer {
  padding-bottom: 2rem;
  margin: 0px 70px;
  width: 90%;
}

.footer-end {
  display: flex;
  justify-content: space-between !important;
}

.social-logo {
  height: 14px;
  margin: 0 7px;
}

// Register screen styles

@media screen and (max-width: 480px) {

  .register-screen {
    background-image: url("./assets/register-background-mobile.png") !important;
  }

  .title {
    display: none !important;
  }

  .title-mobile {
    display: block !important;
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    padding-left: 28px;
    padding-top: 40px;
    padding-bottom: 20px;
    color: #FEFEFE;
    margin-bottom: 20px !important;
    text-shadow: 7px 4px 8px rgba(53, 53, 53, 0.31);
  }

  .partners-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin: 0 auto !important;
    height: auto !important;
  }


  .partner {
    width: 90% !important;
    border-radius: 22px;
    margin: 10px !important;
    padding: 2px 0 !important;
  }

  .partner-logo {
    width: 60px !important;
    height: 60px !important;
  }

  .institution-logo {
    width: 60px !important;
    height: 60px !important;
  }

  .title-card {
    padding-bottom: 10px;
  }

  .title-text {
    font-size: 20px !important;
  }

  .feature-text {
    font-size: 12px !important;
  }

  .batch-name-text {
    font-size: 17px !important;
  }

  .fee-container {
    font-size: 12px !important;
  }

  .fee-text {
    font-size: 11px !important;
  }

  .fee-amount-text {
    font-size: 11px !important;
  }

  .offer-text {
    font-size: 10px !important;
  }

  .offer-text-bold {
    font-size: 14px !important;
  }

  .type {
    width: 100% !important;
  }

  .type-text {
    font-size: 10px !important;
  }

  .limited-offer {
    display: none !important;
  }

  .bold-text {
    font-weight: 700 !important;
    font-size: 14px !important;
  }

  .limited-offer-mobile {
    display: block !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-align: center !important;
    color: #FCFCFE !important;
    width: 90% !important;
    position: sticky;
    bottom: 0;
    background: linear-gradient(0deg, #6D27C6 3.59%, #D797FF 152.38%);
    border-radius: 26px;
    margin: 10px auto;
    margin-top: 0px !important;
    padding: 3px 0;
  }

  .gift-img {
    height: 14px !important;
    width: 14px !important;
  }

  .select-button {
    font-size: 12px !important;
    width: 45% !important;
    height: 40px;
  }

  .popup-container {
    flex-direction: column !important;
    width: 90% !important;
    height: auto !important;
    left: 5% !important;
    transform: translateY(20%) !important;
    padding: 15px 5px !important;
  }

  .form-graphics {
    display: flex;
    width: 100% !important;
  }

  .popup-graphics {
    margin-top: 0rem !important;
    margin-bottom: -5% !important;
    width: 90% !important;
    object-fit: contain;
  }

  .form-input,
  .form-select {
    width: 94.8% !important;
  }

  .form-input::placeholder {
    color: #1F1E41 !important;
    font-family: $font;
  }

  .form-select option {
    padding: 10px 0 !important;
  }

  .form-main-container {
    display: flex;
    width: 100% !important;
    height: auto !important;
    padding-top: 0 !important;
    padding: 10px 0;
    flex-direction: column !important;
    margin-top: 20% !important;
  }

  .form-title-card {
    display: flex;
    flex-direction: column;
    width: 95% !important;
  }

  // .submit-button-mobile {
  //   display: block !important;
  //   background: #1F1E41;
  //   border-radius: 14px;
  //   color: #fff;
  //   font-weight: 500;
  //   width: 110px;
  //   height: 50px;
  //   font-size: 16px;
  //   border: none;
  //   align-self: center;
  // }

  // .submit-button {
  //   display: none !important;
  // }

  .gratitude {
    display: none !important;
  }

  .gratitude-mobile {
    display: flex !important;
    flex-direction: column !important;
    font-size: 20px !important;
    text-align: center;
    padding-bottom: 0px !important;
  }

  .bold-text-mobile {
    color: #8D73E1;
  }

  .error-toast {
    font-size: 12px !important;
    width: 80% !important;
    border-bottom: 10px !important;
  }

  .form-submit {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
  }

  .button-container {
    width: 100% !important;
  }

  .toast-container {
    width: 100% !important;
    justify-content: center !important;
    padding-right: 0 !important;
  }

  .success-toast {
    font-size: 12px !important;
    width: 60% !important;
  }

  .name-input {
    width: 45% !important;
  }

  .number-input {
    width: 45% !important;
  }

  .knowledge-input {
    width: 95% !important;
  }

  .form-content {
    width: 100% !important;
    margin-top: 0 !important;
    margin-top: 0 !important;
  }

  .popup-text {
    border: 1px solid #8059FF !important;
    border-radius: 25px;
    padding: 20px;
  }

  .register-contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-image: url("./assets/register-background-mobile.png") !important;
    // background-size: 100vw 100vh;
    // background-repeat: no-repeat;
    margin: 75px 10px !important;
  }

  .register-contact-mobile {
    display: flex !important;
    flex-direction: column !important;
  }

  .register-contact-desktop {
    display: none !important;
  }

  .earth-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 60% !important;
  }

  .register-contact-page-text-container {
    display: flex;
    align-self: center;
    justify-content: center;
    margin-top: -25% !important;
    z-index: 0;
  }

  .register-contact-page-text-mobile {
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    border: 2px #A54AFF solid;
    border-radius: 30px;
    align-self: center;
    padding: 120px 20px 30px 20px;
  }

  .register-contact-page-img {
    position: relative;
    width: 250px;
    height: 250px;
    z-index: 999;
    margin: auto;
  }

  .type-filter-container {
    width: 45% !important;
    margin-top: -7% !important;
  }

  .divider {
    margin: 0 7% !important;
  }

}

.earth-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  z-index: -900;
}

.register-contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-image: url("./assets/register-background-desktop.png");
  height: 80vh;
  width: 100vw;
}

.register-contact-page-text-container {
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: -5%;
  z-index: 0;
}

.register-contact-page-text-desktop {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  border-radius: 30px;
  align-self: center;
  padding: 120px 20px 30px 20px;
  margin-right: 10px;
}

.register-contact-mobile {
  display: none;
}

.register-contact-desktop {
  display: flex;
  flex-direction: row;
  border-radius: 101px;
  width: 50%;
  padding: 2%;
  background: linear-gradient(180deg, rgba(41, 41, 67, 0.92) 0%, rgba(10, 10, 17, 0.96) 88.01%);
}

.ok-button {
  border-radius: 27px;
  border: 1px solid #8059FF;
  background-color: transparent;
  color: #FFF;
  padding: 8px 20px;
  margin-top: 30px;
  font-size: 0.8rem;
  border: 2px solid #534b85;
}

.register-contact-page-img {
  position: relative;
  width: 250px;
  height: 250px;
  z-index: 999;
  margin: auto;
}

.clock-icon {
  margin-right: 3px;
}

.close-button {
  position: absolute;
  right: 5%;
  top: 2%;
}

.register-screen {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: url("./assets/register-background-desktop.png");
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  z-index: -999;
}

.title {
  font-weight: 700;
  font-size: 56px;
  text-align: left;
  padding-left: 40px;
  padding-top: 50px;
  padding-bottom: 20px;
  color: #FEFEFE;
  text-shadow: 7px 4px 8px rgba(53, 53, 53, 0.31);
}

.title-mobile {
  display: none;
}

.home-title-mobile {
  display: none;
}

.yellow-text {
  color: #FFBE0C;
  text-shadow: 7px 4px 8px rgba(53, 53, 53, 0.31);
}

.partners-container {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  justify-content: center;
  row-gap: 10px;
  column-gap: 20px;
  margin: 40px 50px;
}

.list-container {
  background-color: #fff;
}

.partner {
  background-color: #DAE0F9;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  box-shadow: 30px 30px 30px 20px #1A1A1E;
  border-radius: 28px;
}

.title-card {
  border-radius: 24px;
  border: 1px solid #FFF;
  margin: 0 7px;
  margin-top: 5px;
  margin-bottom: 10px;
  background: linear-gradient(135deg, #F4F5F7 0%, #D6D3E0 100%);
}

.title-container {
  display: flex;
  flex-direction: row;
  padding-top: 18px;

}

.location-filter {
  display: flex;
  flex-direction: row;
  padding-left: 25px;
  align-items: center;
  align-self: center;
  overflow-x: scroll;
  margin-bottom: 30px;
}

.location-item {
  margin: 10px;
  flex-direction: row;
  font-size: 16px;
  border: thin solid #FFFFFF;
  padding: 5px;
  border-radius: 11px;
  padding-inline: 10px;
  background-color: transparent;
  color: #FEFEFE;
}

.timing-text-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

#container1 {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#container2 {
  height: 50%;
  width: 50%;
  overflow: auto;
  padding-right: 20px;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.partner-logo {
  background-color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 18px;
  border-radius: 26px;
}

.institution-details {
  background: linear-gradient(135deg, #F6EFFF 0%, #D6D3E0 100%);
  border-radius: 24px;
  margin: 0px 5px;
  border: 2px solid #FFF;
}

.institution-logo {
  width: 80px;
  height: 80px;
}

.title-text {
  color: #1F1E41;
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  align-self: center;
  margin-left: 20px;
}

.features {
  margin: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.feature-text {
  color: #1F1E41;
  font-weight: 500;
  font-size: 12px;
  margin: 1px 15px;
}

.timings {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin: 10px 40px;
  justify-content: center;
}

.timer-img {
  width: 25px;
}

.batch {
  display: flex;
  flex-direction: column;
  margin: 12px 8px;
  box-shadow: 1px 1px 4px 0px rgba(144, 150, 173, 0.35);
  border-radius: 24px;
  border: 1px solid #F6EFFF;
  background-color: #FFF;
}

.batch-selected {
  border: 1px solid #653FBF;
}

.batch-details {
  flex: 1;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  padding: 10px 10px;
  background: #FFF;
  border-top: 1px dashed #9B9BDB;
  border-bottom: 1px dashed #9B9BDB;
}

.batch-name-container {
  display: flex;
  padding: 10px 0;
  padding-top: 10px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 8px 7px 27px -2px rgba(191, 196, 218, 0.2);
  border-radius: 16px 16px 0px 0px;
  margin-bottom: 15px;
}

.batch-name-text {
  color: #3D3D5C;
  font-size: 25px;
  font-weight: 700;
  margin-left: 10px;
}

.class-type-text {
  color: #3D3D5C;
  font-size: 15px;
  font-weight: 500;
  padding-left: 10px;
  margin-right: 10px;
  border-left: 1px solid #9B9BDB;
}

.batch-timings {
  margin-left: 15px;
  width: 48%;
}

.batch-type {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.batch-type-img {
  margin-bottom: -1.5px;
  margin-right: 2px;
}

.batch-type-text-container {
  padding: 4px 6px;
  background: #F2F3F7;
  color: #3D3D5C;
  border-radius: 8px;
}

.batch-type-text-container-selected {
  background: #8159FF !important;
  color: #FFF !important;
}

.batch-type-text {
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: -0.03em;
}

.batch-timing-list-container {
  margin-top: 5px;
}

.batch-timing-list {
  background: #F2F3F7;
  border-radius: 10px;
  padding: 1px 4px;
  margin-bottom: 5px;
}

.batch-custom-timing {
  color: #3D3D5C !important;
  letter-spacing: -0.03em !important;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  min-height: 90px;
}

.batch-timing {
  text-align: center;
  border: 0.5px solid #B8BED7;
  color: #3D3D5C;
  border-radius: 8px;
  margin: 2.5px 0;
  width: 40%;
  margin: 5px 10px;
  cursor: pointer;
}

.divider {
  border-top: 2px #411C76 solid;
  margin-bottom: -1.4% !important;
  margin: 0 3%;
}

.type-filter-container {
  background-color: #411C76;
  border-radius: 16px;
  width: 11%;
  text-align: center;
  padding: 12px 5px;
  margin: 0 auto;
}

.type-filter {
  border: 2px #8059FF solid;
  color: #8059FF;
  padding: 3px 10px;
  border-radius: 12px;
  margin: 2px;
  cursor: pointer;
}

.type-filter-active {
  background-color: #8059FF;
  color: #FFF;
  border: 2px #8059FF solid;
}

.register-form-screen {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: url("./assets/register-form-background.png");
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  z-index: -999;
}

.batch-timing-text {
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: -0.03em;
}

.batch-timing-selected {
  color: #FFF;
  background-color: #8159FF;
  line-height: 24px;
}

.batch-fees {
  padding-right: 15px;
  width: 54%;
  padding-left: 5px;
}

.fee-details {
  background-color: #110E0E;
  border-radius: 14px;
  padding: 3px 5px;
  width: 100%;
  align-self: flex-end;
  margin: 5px 0;
}

.fee-container {
  display: flex;
  flex-direction: row;
  width: 50%;
  font-size: 14px;
  margin: 0px 0px;
  padding: 10px 0px;
  text-align: center;
}

.fee-text {
  text-align: left;
  font-size: 16px;
}

.fee-amount-text {
  text-align: right;
  font-weight: 700;
  font-size: 15px;
}

.additional-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
  width: 50%;
}

.select-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #F2F3F7;
  border-radius: 14px;
  padding: 10px 5px;
  margin-top: 10px;
  width: 100%;
}

.offer {
  border: 1px dashed #FF641A;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 45%;
}

.gift-img {
  height: 20px;
  width: 20px;
  margin-right: 7px;
}

.offer-text {
  color: #D0004F;
  font-size: 16px;
}

.offer-text-bold {
  font-weight: 700;
  font-size: 20px;
}

.location-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  padding-top: 10px
}

.location-select-container {
  display: flex;
  flex-direction: row;
  width: 50%;
  padding: 10px 0;
  margin-left: 15px;
  margin-top: -5px;
}

.location-select-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #3D3D5C;
}

.location-select {
  width: 65%;
  margin-left: 5px;
  margin-right: 5px;
  height: 30px;
  outline: none;
  border: 0.5px solid #B8BED7;
  border-radius: 8px;
  font-family: $font;
}

.select-button {
  background: #8059FF;
  box-shadow: 8px 7px 27px -2px rgba(191, 196, 218, 0.2);
  border-radius: 12px;
  color: #FFF;
  font-weight: 300 !important;
  font-size: 15px;
  text-transform: capitalize;
  height: 40px;
  width: 20%;
  margin-bottom: 5px;
  margin-right: 10px;
  border: none;
}

.select-button-selected {
  background: linear-gradient(81.15deg, #7B4BE3 1.24%, #A35BF1 113.74%) !important;
  color: #fff;
  border: none;
}

.limited-offer {
  font-size: 16px;
  color: #FCFCFE;
  text-align: center;
  margin: 29px 0;
}

.limited-offer-mobile {
  display: none;
}

.gratitude {
  font-size: 16px;
  color: #FCFCFE;
  text-align: center;
  margin: 29px 0;
  padding-bottom: 20px;
}

.gratitude-mobile {
  display: none;
}

.bold-text {
  font-weight: 700;
}

.timings-text {
  color: #3D3D5C,

}

.form-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 55%;
  border-radius: 24px;
  height: 48%;
  background-color: #F0EFF5;
  margin-top: 5%;
}

.form-title-card {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 87%;
  padding: 5px 0;
  background-color: #F0EFF5;
  border: 2px solid #FFF;
}

.form-container {
  display: flex;
  background-color: #F0EFF5;
  margin: 0 10px;
  border-radius: 20px;
  background: linear-gradient(#292943, #0A0A11);
  height: 90%;
}

.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 90%;
  height: 50%;
  background: #212137;
  border-radius: 30px;
  padding: 20px 0px;
  margin: auto;
  transform: translateY(50%);
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.7% 0;
  border-radius: 30px;
  margin-left: 7px;
}

.form-graphics {
  width: 50%;
  height: auto;
}

.popup-graphics {
  margin-left: 10%;
  margin-top: 5%;
}

.popup-text {
  margin-top: 10px;
  border-top: 1px solid #3B3B56;
  padding-top: 20px;
  width: 80%;
  margin-left: 20%;
  margin-right: 20%;
}

.bold-blue-text {
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #B671F2;
  text-align: left;
}

.form-input,
.form-select {
  box-sizing: border-box;
  // border: 1px solid #807EBD;
  border: none;
  background: #FFFFFF;
  border-radius: 15px;
  height: 42px;
  margin: 5px 6px;
  padding: 0 10px;
  outline: none;
}

.form-input::placeholder {
  color: #1F1E41 !important;
  font-family: $font;
}

.form-select option {
  padding: 10px 0 !important;
}

.name-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.name-input {
  width: 45%;
}

.email-input {
  width: 93%;
}

.number-input {
  width: 45%;
}

.number-input-error {
  border: 1px solid #D0004F;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.current-position-input {
  width: 93%;
}

.ielts-plan-input {
  width: 80%;
}

.knowledge-input {
  width: 93%;
}

.final-select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.submit-button-mobile {
  display: none;
}

.button-container {
  width: 55%;
  display: flex;
  justify-content: flex-start;
  align-self: center;
}

.toast-container {
  width: 100%;
  padding-right: 2rem;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 10px;
}

.submit-button {
  display: block;
  background: #8059FF;
  border-radius: 14px;
  color: #fff;
  font-weight: 500;
  width: 142px;
  height: 45px;
  font-size: 15px;
  border: none;
  margin-top: 5px;
  margin-left: 4%;
  align-self: flex-start;
}

.padding-bottom {
  padding-bottom: 30px;
}

.form-submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.success-toast {
  display: none;
  width: 50%;
  background-color: #28d169;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 10px;
  z-index: 999;
  border-radius: 10px;
}

.error-toast {
  display: none;
  width: 30%;
  background-color: #F64259;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 10px;
  border-radius: 10px;
}

.error-toast.show {
  display: block !important;
  -webkit-animation: error-fadein 0.5s, error-fadeout 0.5s 5s;
  animation: error-fadein 0.5s, error-fadeout 0.5s 5s;
}

@-webkit-keyframes error-fadein {
  from {
    left: 26.5%;
    opacity: 0;
  }

  to {
    left: 26.5%;
    opacity: 1;
  }
}

@keyframes error-fadein {
  from {
    left: 26.5%;
    opacity: 0;
  }

  to {
    left: 26.5%;
    opacity: 1;
  }
}

@-webkit-keyframes error-fadeout {
  from {
    left: 26.5%;
    opacity: 1;
  }

  to {
    left: 26.5%;
    opacity: 0;
  }
}

@keyframes error-fadeout {
  from {
    left: 26.5%;
    opacity: 1;
  }

  to {
    left: 26.5%;
    opacity: 0;
  }
}

.success-toast.show {
  display: block !important;
  -webkit-animation: success-fadein 0.5s, success-fadeout 0.5s 3s;
  animation: success-fadein 0.5s, success-fadeout 0.5s 3s;
}

@-webkit-keyframes success-fadein {
  from {
    left: 20%;
    opacity: 0;
  }

  to {
    left: 20%;
    opacity: 1;
  }
}

@keyframes success-fadein {
  from {
    left: 20%;
    opacity: 0;
  }

  to {
    left: 20%;
    opacity: 1;
  }
}

@-webkit-keyframes success-fadeout {
  from {
    left: 20%;
    opacity: 1;
  }

  to {
    left: 20%;
    opacity: 0;
  }
}

@keyframes success-fadeout {
  from {
    left: 20%;
    opacity: 1;
  }

  to {
    left: 20%;
    opacity: 0;
  }
}

// Contact screen styles

@media screen and (max-width: 480px) {

  .contact-screen {
    width: 100vw !important;
    background-image: url("./assets/contact-background-mobile.png") !important;
  }

  .contact-vector {
    display: none !important;
  }

  .contact-vector-mobile {
    display: block !important;
    position: absolute;
    width: 95% !important;
    top: 42.7% !important;
    left: 5% !important;
  }

  .contact-details {
    margin-left: 15% !important;
    padding-top: 30% !important;
  }

  .contact-title {
    font-size: 35px !important;
    margin-bottom: 10px;
  }

  .contact-subtitle {
    font-size: 14px !important;
  }

  .contact {
    width: 70% !important;
    padding: 10px 15px !important;
    margin: 15px 0 !important;
  }

  .about-us {
    width: 100vw !important;
    margin: 20px !important;
  }

  .about-content {
    width: 90% !important;
  }

  .extra-content-text {
    width: 90% !important;
    margin-bottom: 30px !important;
  }

  .extra-content-img {
    display: none !important;
  }

  .about-stats {
    flex-direction: column !important;
    width: 85% !important;
  }

  .stat {
    width: 81% !important;
    justify-content: center !important;
  }

}

.contact-screen {
  height: 100vh;
  width: 100vw;
  background-image: url("./assets/contact-background-desktop.png");
  background-size: 100vw 100vh;
}

.contact-details {
  margin-left: 52%;
}

.contact-title {
  font-weight: 700;
  font-size: 58px;
  background: linear-gradient(47.18deg, #8155FF 10.99%, #D899FF 90.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.contact-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #3D3D5C;
}

.contact {
  background: #F2F3F7;
  box-shadow: -4px -4px 7px #FFFFFF, 3px 4px 8px rgba(191, 196, 218, 0.51), inset -1px -2px 3px -1px #FFFFFF;
  border-radius: 12px;
  width: 50%;
  padding: 1rem 15px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.contact-vector {
  position: absolute;
  width: 49.3%;
  left: 10%;
}

.contact-vector-mobile {
  display: none;
}

.about-us {
  margin: 50px 100px;
}

.about-title {
  font-weight: 700;
  font-size: 56px;
  line-height: 58px;
  letter-spacing: -0.03em;
  background: linear-gradient(108.03deg, #E5A570 -15.36%, #F84196 37.1%, #772EF1 111.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 4px 3px 8px rgba(9, 8, 14, 0.28);
  margin-bottom: 5px;
}

.about-content {
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  text-align: justify;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  padding-bottom: 65px;
  border-bottom: 1px solid #9B9BDB;
}

.extra-content {
  display: flex;
  flex-direction: row;
}

.extra-content-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  text-align: justify;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin-top: 65px;
}

.about-stats {
  display: flex;
  flex-direction: row;
  background: #1C102E;
  border-radius: 42px;
  padding: 10px 10px;
}

.stat {
  display: flex;
  flex-direction: row;
  border: 1px solid #7B04ED;
  border-radius: 36px;
  margin: 10px;
  // border-image-source: linear-gradient(259.54deg, #7B04ED -2.75%, #FF8194 108.3%);
  padding: 25px 20px;
  align-items: center;
  justify-content: center;
  width: 21%;
}

.stat-logo {
  margin-right: 20px;
}

.stat-content {
  display: flex;
  flex-direction: column;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #FFFFFF;
}

.stat-title {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 5px;
}

.stat-text {
  font-weight: 500;
  font-size: 14px;
  width: 95%;
}

.home-navigation {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.home-navigation-icon {
  font-size: 22px;
  color: #63527C;
  border: 1px solid #63527C;
  border-radius: 30px;
  padding: 10px 15px;
  margin-right: 10px;
}

.home-navigation-text {
  font-size: 18px;
  color: #63527C;
  border: 1px solid #63527C;
  border-radius: 30px;
  padding: 10px 15px;
}

.app-launch {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.lsoon-img-mobile {
  display: none;
}

.lsoon-img {
  width: auto;
  max-width: 1268px;
}