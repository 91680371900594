.contact_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  justify-content: center;
  gap: 80px;
}

.contact_item_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.contact_item_img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.contact_item_text {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
}

@media (max-width: 1040px) {
  .contact_container {
    flex-direction: column;
    gap: 20px;
    margin-left: 10%;
    margin-right: 10%;
    height: auto;
    align-items: flex-start;
  }

  .contact_small_container {
    background-color: #151415;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    padding-left: 20px;
  }

  .contact_top_small_container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .contact_item_text {
    font-size: 12px;
    line-height: 22px;
  }

  .contact_item_img {
    width: 30px;
    height: 30px;
  }
}
