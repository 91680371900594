.about_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.about_text_header {
  font-size: 42px;
  line-height: 50px;
  font-weight: 700;
  background: linear-gradient(to right, #025b8c, #00ffbd);
  -webkit-background-clip: text;
  color: transparent;
}

.about_desc_text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  width: 73%;
  color: rgba(255, 255, 255, 0.8);
  padding-left: 10px;
  padding-right: 10px;
}

.about_img_container {
  height: 420px;
  margin-top: 80px;
}

.about_img {
  width: 100%;
  height: 100%;
}

.about_img_small_screen_container {
  display: none;
}

@media (max-width: 1040px) {
  .about_img_container {
    display: none;
  }

  .about_img_small_screen_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
  }

  .about_img_small_screen_img {
    width: 94%;
    object-fit: cover;
    border-radius: 8px;
  }

  .college_container {
    width: 100%;
    margin-left: 6%;
    position: relative;
  }

  .aboutWidth1 {
    height: 150px;
  }

  .aboutWidth2 {
    height: 150px;
  }

  .aboutWidth3 {
    height: 150px;
  }

  .aboutWidth4 {
    height: 150px;
  }

  .aboutWidth5 {
    height: 150px;
  }

  .aboutWidth6 {
    height: 490px;
  }

  .college_container_text {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  .college_text_img {
    width: 230px;
    height: 130px;
    object-fit: contain;
  }

  .about_desc_text {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    width: 90%;
  }
}

@media (max-width: 700px) {
  .about_text_header {
    font-size: 26px;
  }
  .aboutWidth1 {
    height: 118px;
  }

  .aboutWidth2 {
    height: 118px;
  }

  .aboutWidth3 {
    height: 57px;
  }

  .aboutWidth4 {
    height: 118px;
  }

  .aboutWidth5 {
    height: 110px;
  }

  .aboutWidth6 {
    height: 290px;
  }

  .about_img_small_screen_container {
    gap: 10px;
  }

  .about_img_small_screen_img {
    object-fit: contain;
  }
}
