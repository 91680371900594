.home_main_container {
  position: relative;
  display: flex;
  height: 100vh;
}

.top_corner_image_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 235px;
  height: 235px;
}

.top_corner_image {
  width: 100%;
}

.right_image_container {
  width: 370px;
  height: 740px;
  position: absolute;
  top: 0;
  right: 0;
}

.right_image {
  width: 100%;
  height: 100%;
}

.top_section_text_container {
  position: absolute;
  left: 3%;
  margin-top: 235px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.kerala_text {
  font-size: 40px;
  line-height: 44px;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.7);
}

.large_text {
  color: #ffffff;
  font-weight: 900;
}

.ai_image_conatiner {
  width: 962px;
  height: 116px;
}

.ai_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.venue_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 616px;
  height: 54px;
  margin-top: 40px;
}

.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.date_image_container {
  width: 50px;
  height: 50px;
}

.date_image {
  width: 100%;
  height: 100%;
}

.date_text {
  font-size: 16px;
  line-height: 27px;
}

.venue {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.venue_image_container {
  width: 38px;
  height: 50px;
}

.venue_text_container {
  display: flex;
  flex-direction: column;
}

.eco_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
}

.eco_text {
  font-size: 20px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.7);
}

.google_image_container {
  width: 183px;
  height: 55px;
}

.google_image {
  width: 100%;
  height: 100%;
}

@media (max-width: 1300px) {
  .top_section_text_container {
    width: 70%;
  }
}

@media (max-width: 1100px) {
  .right_image_container {
    width: 300px;
    height: 600px;
    top: 15vh;
  }
}

@media (max-width: 1040px) {
  .top_corner_image_container {
    width: 135px;
    height: 135px;
  }

  .right_image_container {
    width: 135px;
    height: 250px;
    top: 300px;
  }

  .top_section_text_container {
    width: 94%;
    margin-top: 80px;
  } 

  .kerala_text {
    text-align: center;
  }

  .ai_image_conatiner {
    width: 100%;
    height: 100px;
  } 

  .venue_container {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    gap: 30px;
    width: auto;
  }

  .venue_image_container {
    width: 50px;
  }

  .date_image {
    object-fit: contain;
  }

  .eco_container {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 750px) {
  .home_main_container {
    height: 70vh;
  }

  .top_corner_image_container {
    width: 100px;
    height: 100px;
  }

  .right_image_container {
    width: 100px;
    height: 200px;
    top: 300px;
  }

  .kerala_text {
    font-size: 20px;
    line-height: 25px;
  }

  .ai_image_conatiner {
    height: 41px;
  } 

  .date_text {
    font-size: 10px;
    line-height: 18px;
  }

  .date_image_container {
    width: 30px;
    height: 30px;
  }

  .venue_image_container {
    width: 30px;
    height: 30px;
  }

  .eco_text {
    font-size: 12px;
    line-height: 18px;
  }

  .google_image_container {
    width: 133px;
    height: 40px;
  }
}

@media (max-width: 380px) {
  .kerala_text {
    font-size: 16px;
    line-height: 22px;
  }
}
