.aiSummitHomeScreenMainContainer {
  font-family: "Montserrat", sans-serif;
  background-color: #000000;
  color: #ffffff;
  margin: 0;
}

.container {
  margin-left: 3%;
  margin-right: 3%;
}

.summit_description_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}

.welcome_text {
  font-size: 28px;
  line-height: 40px;
}

.flag_img_container {
  width: 50%;
  height: 135px;
}

.flag_img {
  width: 100%;
  height: 100%;
}

.flag_desc_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 18px;
  line-height: 28px;
  margin-top: 20px;
  width: 70%;
  color: rgba(255, 255, 255, 0.8);
}

.register_container {
  height: 175px;
  margin-bottom: 50px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 80px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.register_button_container {
  width: 204px;
  height: 54px;
  background-color: #000a1d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
}

.register_button_container:hover {
  cursor: pointer;
  opacity: 0.9;
}

.register_img {
  width: 20px;
  height: 24px;
  object-fit: contain;
}

.register_button_text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

.footer_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_img {
  width: 989px;
  height: 99px;
  object-fit: contain;
}

.panel_container {
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 50px;
  gap: 10px;
  margin-top: 70px;
}

.panel_header_img {
  width: 90%;
  height: 115px;
  object-fit: contain;
  margin-bottom: 50px;
}

.panel_img {
  width: 90%;
  object-fit: contain;
}

.panel_img_small {
  display: none;
}

.register_small_container {
  display: none;
}

.footer_small_container {
  display: none;
}

@media (max-width: 1300px) {
  .register_container {
    background-size: contain;
  }

  .flag_desc_container {
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media (max-width: 1040px) {
  .container {
    margin-left: 0;
    margin-right: 0;
  }

  .register_container {
    display: none;
  }

  .register_small_container {
    display: flex;
    width: 94%;
    height: 300px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .panel_header_img {
    height: 90px;
  }

  .register_button_container {
    margin-bottom: 20px;
  }

  .footer_container {
    display: none;
  }

  .footer_small_container {
    display: flex;
    padding-bottom: 50px;
    align-items: center;
    justify-content: center;
  }

  .footer_small_img {
    width: 85%;
    height: 200px;
    object-fit: contain;
  }
}

@media (max-width: 805px) {
  .panel_header_img {
    height: 50px;
  }
}

@media (max-width: 750px) {
  .panel_img {
    display: none;
  }

  .panel_header_img {
    margin-bottom: 20px;
  }

  .panel_img_small {
    display: flex;
    width: 100%;
    object-fit: contain;
  }
}

@media (max-width: 700px) {
  .welcome_text {
    font-size: 16px;
    line-height: 26px;
  }

  .flag_img_container {
    height: 77px;
  }

  .flag_desc_container {
    font-size: 14px;
    line-height: 22px;
    width: 90%;
  }

  .register_button_container {
    margin-bottom: 60px;
    width: 158px;
    height: 36px;
    border-radius: 10px;
  }

  .register_button_text {
    font-size: 14px;
    line-height: 20px;
  }

  .footer_small_img {
    height: 140px;
  }
}

@media (max-width: 600px) {
  .panel_header_img {
    height: 31px;
  }
}

@media (max-width: 477px) {
  .register_button_container {
    margin-bottom: 90px;
  }
}
