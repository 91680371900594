.welcome_kerala_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.welcome_kerala_img {
  width: 916px;
  height: 28px;
}

.workshop_container {
  background-color: #edf5ff;
  border-radius: 77px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.workshop {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.workshopImage_container {
  width: 340px;
  height: 340px;
}

.workshopImage {
  width: 100%;
  height: 100%;
  border-radius: 77px;
  object-fit: contain;
}

.workshop_desc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.work_main_header {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  background: linear-gradient(to right, #025b8c, #00ffbd);
  -webkit-background-clip: text;
  color: transparent;
}

.work_header {
  font-size: 28px;
  line-height: 30px;
  font-weight: 600;
  color: #000000;
}

.work_text {
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.welcome_kerala_small_img {
  display: none;
}

.workshop_small_desc {
  display: none;
}

.work_text_small {
  display: none;
}

@media (max-width: 1040px) {
  .workshop_container {
    margin-left: 3%;
    margin-right: 3%;
  }

  .welcome_kerala_img {
    display: none;
  }

  .welcome_kerala_small_img {
    display: block;
    width: 94%;
    object-fit: contain;
  }

  .workshop {
    flex-direction: column;
    width: 70%;
    margin-bottom: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .work_desc_large {
    display: none;
  }

  .work_text {
    display: none;
  }

  .workshop_small_desc {
    display: block;
  }

  .work_text_small {
    display: block;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    text-align: center;
  }

  .work_main_header {
    text-align: center;
  }

  .work_header {
    text-align: center;
  }
}

@media (max-width: 700px) {
  .welcome_kerala_small_img {
    height: 31px;
  }

  .workshop_container {
    border-radius: 20px;
  }

  .workshopImage_container {
    width: 186px;
    height: 186px;
  }

  .workshopImage {
    border-radius: 20px;
  }

  .work_main_header {
    font-size: 14px;
    line-height: 20px;
  }

  .work_header {
    font-size: 18px;
    line-height: 25px;
  }

  .work_text_small {
    font-size: 14px;
    line-height: 20px;
  }
}
