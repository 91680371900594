.benefits_container {
  width: 100%;
  height: 440px;
  border-radius: 37px;
  background-color: #151415;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.benefit_img_container {
  width: 524px;
  height: 375px;
  margin-right: 60px;
}

.benefit_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.benefit_item_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 60px;
}

.benefit_header {
  font-size: 42px;
  line-height: 50px;
  font-weight: 700;
  background: linear-gradient(to right, #025b8c, #00ffbd);
  -webkit-background-clip: text;
  color: transparent;
}

.benefit_items_container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.benefit_item {
  height: 50px;
  border-radius: 15px;
  display: flex;
  background: linear-gradient(to right, #025b8c, #00ffbd);
  padding: 1px;
}

.inner_benefit_item {
  width: 100%;
  height: 100%;
  background-color: #000000;
  border-radius: 15px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.benefit_small_screen_container {
  display: none;
}

@media (max-width: 1040px) {
  .benefits_container {
    display: none;
  }

  .benefit_small_screen_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .benefit_small_img_container {
    height: 600px;
    width: 94%;
  }

  .benefit_small_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 700px) {
  .benefit_small_img_container {
    height: 410px;
  }
}
