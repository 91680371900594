.program_container {
  position: relative;
  display: flex;
  align-items: center;
  height: 1268px;
  margin-bottom: 350px;
  z-index: 1;
}

.program_top_img {
  width: 300px;
  height: 600px;
  position: absolute;
  top: -250px;
  left: 0;
  z-index: -1;
}

.program_bottom_img {
  position: absolute;
  width: 300px;
  height: 600px;
  bottom: -75px;
  right: 0;
  z-index: 1;
}

.program_schedule_container {
  width: 100%;
  height: 100%;
  margin-left: 6%;
  margin-right: 6%;
  margin-top: 50px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.program_schedule_header {
  font-size: 42px;
  line-height: 50px;
  background: linear-gradient(to right, #025b8c, #00ffbd);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
  text-align: center;
}

.prog_time_container {
  position: absolute;
  top: 82px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.prog_date_container {
  display: flex;
  flex-direction: column;
}

.prog_date_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.prog_date_num_text {
  font-size: 40px;
  line-height: 56px;
  font-weight: 700;
  color: #19fb9b;
}

.prog_day_text {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.prog_item {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #19fb9b;
}

.prog_item_schedule_container {
  width: 860px;
  height: auto;
  border-radius: 40px;
  background-color: #19161c;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  gap: 15px;
}

.prog_item_schedule_top {
  display: flex;
  flex-direction: row;
  gap: 50px;
  font-size: 16px;
  line-height: 25px;
}

.prog_item_schedule {
  display: flex;
  flex-direction: row;
  gap: 50px;
  font-size: 16px;
  line-height: 25px;
}

.prag_time {
  width: 170px;
}

.online_container {
  width: 280px;
  height: 64px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 750px;
  background: linear-gradient(to right, #025b8c, #00ffbd);
  padding: 2px;
}

.inner_online_container {
  width: 100%;
  height: 100%;
  background-color: #000000;
  border-radius: 30px;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.online_schedule_container {
  position: absolute;
  top: 880px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.online_schedule_items_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.online_shedule_text {
  width: 860px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #19161c;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 40px;
}

.prog_item_small {
  display: none;
}

@media (max-width: 1390px) {
  .program_container {
    height: 1390px;
  }
}

@media (max-width: 1040px) {
  .program_container {
    height: 1750px;
    margin-bottom: 0px;
  }

  .program_top_img {
    width: 150px;
    height: 300px;
    top: -100px;
  }

  .program_bottom_img {
    display: none;
  }

  .prog_time_container {
    flex-direction: column;
    gap: 30px;
  }

  .prog_date_container {
    align-items: center;
  }

  .prog_item_schedule_container {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    height: auto;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 10px;
  }

  .prog_item {
    font-size: 18px;
    line-height: 25px;
  }

  .online_container {
    width: 128px;
    height: 38px;
    top: 800px;
  }

  .inner_online_container {
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
  }

  .online_schedule_container {
    top: 900px;
  }

  .online_schedule_items_container {
    flex-direction: column;
    gap: 20px;
  }

  .prog_item_large {
    display: none;
  }

  .prog_item_small {
    display: block;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    color: #19fb9b;
  }

  .online_shedule_text {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    border-radius: 20px;
  }

  .prog_item_schedule {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .prog_item_schedule_top {
    font-size: 10px;
    line-height: 20px;
    gap: 20px;
  }

  .prog_item_schedule {
    font-size: 10px;
    line-height: 20px;
  }
}

@media (max-width: 700px) {
  .prog_item {
    font-size: 18px;
    line-height: 25px;
  }

  .prog_item_small {
    font-size: 18px;
    line-height: 25px;
  }

  .program_schedule_header {
    font-size: 26px;
    line-height: 35px;
  }

  .prag_time {
    width: 40%;
  }

  .progr_item {
    width: 40%;
  }
  
  .prog_single {
    width: 50%;
  }
}

@media (max-width: 503px) {
  .online_container {
    top: 850px;
  }
  .online_schedule_container {
    top: 910px;
  }
}

@media (max-width: 438px) {
  .program_container {
    height: 1800px;
  }

  .online_container {
    top: 910px;
  }

  .online_schedule_container {
    top: 970px;
  }
}

@media (max-width: 412px) {
  .program_container {
    height: 1870px;
  }
  .online_container {
    top: 970px;
  }

  .online_schedule_container {
    top: 1030px;
  }
}

@media (max-width: 405px) {
  .program_top_img {
    top: 0px;
  }

  .program_container {
    height: 1900px;
  }

  .online_container {
    top: 870px;
  }

  .online_schedule_container {
    top: 960px;
  }
}

@media (max-width: 380px) {
  .prog_item_small {
    font-size: 16px;
    line-height: 20px;
  }

  .prog_item {
    font-size: 16px;
    line-height: 20px;
  }
}
